<template>
  <v-row class="subscription_product-row" :class="{ 'pb-4': length > 1 }" wrap>
    <v-col cols="2" sm="2" md="auto" align-self="center">
      <v-icon class="handle cursor-grab">mdi-drag-variant</v-icon>
    </v-col>

    <v-col cols="5" sm="3" md="2" lg="1">
      <SearchSelectByArtNr
        v-if="!subscriptionProduct.isDirty"
        :key="`nr-${index}`"
        v-model="subscriptionProduct.artNr"
        :vid="`nr-${index}`"
        @input="setArtNr"
      />
      <VTextFieldWithValidation
        v-else
        v-model="subscriptionProduct.artNr"
        label="Nr"
        name="nr"
        :vid="`nr-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Nr"
        disabled
      />
    </v-col>

    <v-col cols="5" sm="9" md="3" lg="3">
      <SearchSelectByTitle
        v-if="!subscriptionProduct.isDirty"
        :key="`name-${index}`"
        v-model="subscriptionProduct.name"
        :vid="`name-${index}`"
        @input="setName"
      />

      <VTextFieldWithValidation
        v-else
        v-model="subscriptionProduct.name"
        label="Name"
        name="name"
        :vid="`name-${index}`"
        rules="required"
        placeholder="Name"
      />
    </v-col>

    <v-col cols="12" sm="5" md="4">
      <VTextFieldWithValidation
        v-model="subscriptionProduct.description"
        label="Description"
        name="description"
        rules="required"
        :vid="`description-${index}`"
        placeholder="Enter Description"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="subscriptionProduct.price"
        label="Price"
        name="price"
        :vid="`price-${index}`"
        rules="required|decimal|min_value:0"
        placeholder="Price"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="subscriptionProduct.tax"
        label="Tax"
        name="tax"
        :vid="`tax-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Tax"
      />
    </v-col>

    <v-col cols="12" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="subscriptionProduct.discount"
        label="Discount"
        name="discount"
        :vid="`discount-${index}`"
        rules="integer|min_value:0"
        placeholder="Discount"
      />
    </v-col>

    <v-col cols="10" sm="4" lg="2">
      <VSelectWithValidation
        v-model="subscriptionProduct.discount_type"
        label="Discount Type"
        :items="discountTypes"
        menu-props="offset-y"
        placeholder="Select Discount Type"
      />
    </v-col>

    <v-col cols="2" sm="1" md="1" class="text-right">
      <div class="actions">
        <!-- // TODO: [Future Feature] Add Save Product to Productlist Button -->
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn class="pa-1 ml-1 mt-4 remove-button" color="error" icon v-on="on" @click="removeSubscriptionProduct">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Remove Product</span>
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import SearchSelectByArtNr from '@/components/Products/SearchSelectByArtNr'
import SearchSelectByTitle from '@/components/Products/SearchSelectByTitle'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation.vue'
import { discountTypes } from '@/config'

export default defineComponent({
  name: 'SubscriptionProductRow',
  components: {
    SearchSelectByArtNr,
    SearchSelectByTitle,
    VTextFieldWithValidation,
    VSelectWithValidation,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    subscriptionProduct: {
      type: Object,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    if (props.subscriptionProduct.artNr) {
      props.subscriptionProduct.isDirty = true
    }

    const setArtNr = product => {
      Object.assign(props.subscriptionProduct, product)
      props.subscriptionProduct.name = product.title
      props.subscriptionProduct.description = product.subscriptionstext
      props.subscriptionProduct.price = product.price
      props.subscriptionProduct.isDirty = true
    }

    const setName = product => {
      Object.assign(props.subscriptionProduct, product)
      props.subscriptionProduct.name = product.title
      props.subscriptionProduct.description = product.subscriptionstext
      props.subscriptionProduct.price = product.price
      props.subscriptionProduct.isDirty = true
    }

    const removeSubscriptionProduct = _ => {
      emit('removeSubscriptionProduct', props.index)
    }

    return {
      setArtNr,
      setName,
      removeSubscriptionProduct,
      discountTypes,
    }
  },
}) //
</script>

<style lang="scss">
.theme--dark {
  .subscription_product-row {
    & + .subscription_product-row {
      border-top: 1px solid rgba(map-get($shades, 'white'), 0.12);
    }
  }
}

.theme--light {
  .subscription_product-row {
    & + .subscription_product-row {
      border-top: 1px solid rgba(map-get($shades, 'black'), 0.12);
    }
  }
}
</style>
